<template>
  <div>
    <v-img
      v-if="iconName && iconName.length > 0"
      :src="iconPath"
      :width="size"
      eager
      @click="click"
    />
  </div>
</template>

<script>
import { themePaths } from '../../utils/themeConfig'
export default {
  name: 'DisplayIcon',
  props: {
    iconName: {
      type: String,
      required: true
    },
    size: {
      type: String,
      default: '40px'
    }
  },
  computed: {
    iconPath () {
      const currentTheme = this.$store.getters['theme/currentThemeName']
      if (!this.iconName || this.iconName.length < 1) {
        return ''
      }

      const themePath = themePaths[currentTheme] || themePaths.default

      return require(`@/assets/icons/${themePath}/${this.iconName}`)
    }
  },
  methods: {
    click () {
      this.$emit('icon-clicked', this.iconName)
    }
  }
}
</script>
